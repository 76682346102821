<template>
    <div class="nav-bar">
        <NavBarLanding />
    </div>
    <LandingPage />
    <!--  <router-view /> -->
</template>

<script>
import NavBarLanding from './views/BasicComponents/NavBarLanding.vue';
import LandingPage from './views/LandingPage/LandingPage.vue';

export default {
    name: 'LandingApp',
    components: {
        NavBarLanding,
        LandingPage,
    }
}
</script>

<style scoped>
.nav-bar {
    position: sticky;
    top: 0;
    z-index: 15;
}

/* RESPONSIVE */
@media (min-width:768px) {
    .nav-bar {
        width: calc(100% + 8px);
    }
}
</style>