<template>
    <h2 class="ppalText">{{ppalText}} <span class="secondText">{{secondText}}</span></h2>
</template>

<script>
export default {
    name: 'TitleSecodary',
    props:  {
        ppalText: {
            type: String,
        },
        secondText: {
            type: String,
        },
    },
}
</script>

<style scoped>
h2{
    font-size: 40px;
    font-weight: 600;
    letter-spacing: 0px;
    line-height: 45px;
    margin-bottom: 20px;
}
.ppalText{
    color: var(--dark-violet);
}
.secondText{
    /* color: var(--light-violet-invert);
    -webkit-filter: invert(100%);
    filter: invert(100%); */
    color: var(--dark-violet);
}
/* RESPONSIVE */
@media (min-width:500px) {
    h2{
        font-size: 50px;
        letter-spacing: 2px;
        line-height: 55px;
        margin-bottom: 10px;
    }
}
@media (min-width:992px) {
    h2{
        font-size: 60px;
        line-height: 62px;
        margin-bottom: 8px;
    }
}
@media (min-width:1200px) {
    h2{
        font-size: 70px;
        line-height: 72px;
    }
}
</style>