<template>
    <!-- <router-link v-if="to" :to="to" class="no-decoration"> -->
        <div class="card" :class="{'soft-violet':background}">
            <div>
                <img :src=icon :alt=altIcon />
            </div>
            <div>
                <p class="title">{{ title }}</p>
                <div class="description">
                    <slot name="description"></slot>
                </div>
            </div>
        </div>
    <!-- </router-link> -->
</template>

<script>
export default {
    name: 'CardTreatmentLanding',
    components: {
    },
    props:  {
        icon: {
            type: String,
        },
        altIcon: {
            type: String,
        },
        title: {
            type: String,
        },
        description: {
            type: String,
        },
        background: {
            type: Boolean,
            default: false,
        },
        to: {
            type: String,
        }
    },
}
</script>

<style scoped>
.no-decoration{
    text-decoration: none;
}
.card{
    border-radius: 1rem;
    background-color: var(--white);
    border: none;
    padding: 1.5rem;
    display: flex;
    justify-content: space-between;
    /* min-height: 450px; */
    background: var(--card-gradient);
}
.card.soft-violet{
    background: var(--soft-hover-violet);
}
.title{
    font-weight: 500;
    font-size: 35px;
    line-height: 40px;
    hyphens: auto;
    word-wrap: break-word;
    padding-top: 2.5rem;
}
/* RESPONSIVE */
@media (max-width: 992px) {
    .card{
        height: auto;
    }
}
@media (min-width: 992px) {
    /* .card{
        min-height: 450px;
    } */
}
@media (min-width:575px) {
    .title{
        font-size: 3rem;
        line-height: 2.9rem;
    }
}
</style>