<template>
    <section class="mt-8 col-12 col-lg-11 mx-auto">
        <div class="col-12">
            <SectionName text="Contacto" class="d-flex justify-content-center" />
            <TitleSecodary class="text-center mt-4" ppalText="Queremos saber de tí."/>
        </div>
        <ContactInfo class="mt-6 col-12 col-lg-11 m-auto flex-colum"/>
    </section>
</template>

<script>
import ContactInfo from '../ContactPage/ContactInfo.vue';
import SectionName from '@/components/SectionName.vue'; 
import TitleSecodary from '@/components/TitleSecodary.vue'; 

export default {
    name: 'SectionContact',
    components: {
        ContactInfo,
        SectionName,
        TitleSecodary
    },
}
</script>

<style>
</style>