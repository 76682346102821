import { createRouter, createWebHistory } from "vue-router";
import LandingPage from "@/views/LandingPage/LandingPage.vue";
/* const LandingPage = () => import('@/views/LandingPage/LandingPage.vue'); */

const routes = [
    { path: '/', component: LandingPage },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

router.beforeEach((to, from, next) => {
    window.scrollTo(0, 0);
    next();
});

export default router;