<template>
    <main id="landing">
        <LandingPresentation />
        <SectionClinic class="mt-8"/>
        <LandingTreatments />
        <SectionAppointment class="mt-8"/>
        <LandingPathologies />
        <LandingAboutUs />
        <SectionContact />
    </main>
    <FooterBarLanding class="mt-6"/>
</template>

<script>
import LandingPresentation from './LandingPresentation.vue';
import SectionClinic from '../HomePage/SectionClinic.vue';
import SectionAppointment from '../HomePage/SectionAppointment.vue';
import LandingPathologies from './LandingPathologies.vue';
import SectionContact from '../HomePage/SectionContact.vue';
import FooterBarLanding from '../BasicComponents/FooterBarLanding.vue';
import LandingAboutUs from './LandingAboutUs.vue';
import LandingTreatments from './LandingTreatments.vue';

export default {
    name: 'LandingPage',
    components: {
        LandingPresentation,
        SectionClinic,
        LandingPathologies,
        SectionAppointment,
        SectionContact,
        FooterBarLanding,
        LandingAboutUs,
        LandingTreatments,
    },
}
</script>

<style scoped>

</style>