<template>
    <header>
        <nav class="navbar navbar-expand-lg">
            <div class="container-fluid">
                <a href="https://www.clinicanovocel.cl/" class="navbar-brand" aria-label="Home Clínica Novocel" target="_blank">
                    <picture>
                        <source srcset="../../assets/img/logos/svg/logo-horizontal-color.svg" type="image/svg+xml" />
                        <source srcset="../../assets/img/logos/web/logo-horizontal-color.webp" type="image/webp">
                        <source srcset="../../assets/img/logos/png/logo-horizontal-color.png" type="image/png">
                        <img class="logo" src="../../assets/img/logos/svg/logo-horizontal-color.svg" alt="Logo Novocel - Medicina regenerativa" />
                    </picture>
                </a>
                <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon"></span>
                </button>
                <div class="sections collapse navbar-collapse" id="navbarSupportedContent">
                    <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                        <li class="nav-item">
                            <ButtonAction link="https://s3.philaxmed.cl/ReservaOnline.html?mc=Cl%C3%ADnicaNovocel#_" text="Agenda aquí" :targetBlank=true />
                        </li>
                        <li class="nav-item">
                            <ButtonAction link="tel:+56930580356" text="+56930580356" :targetBlank=true />
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    </header>
</template>

<script>
import ButtonAction from '@/components/ButtonAction.vue';

export default {
    name: 'NavBarLanding',
    components: {
        ButtonAction,
    }
}
</script>

<style scoped>
header{
    position: relative;
    z-index: 10;
}
.navbar {
    background-color: var(--white);
    height: 64px;
    border-radius: 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 6px 16px;
}
.navbar-nav .nav-item{
    padding: 0 0.5rem;
    width: 100%;
}
.navbar-nav .nav-item .action-button.violet {
    width: 100%;
    margin: 0.5rem 0 0 0; /* 0.5rem 0; */
}
.dropdown-item.active, .dropdown-item:active, .dropdown-item:hover, .dropdown-item:focus{
    background-color: var(--soft-hover-violet);
    color: var(--dark-violet);
}
ul.dropdown-menu{
    border: none;
    border-radius: 0.6rem;
    top: 58px;
    margin-bottom: 1rem;
}
.navbar-nav  a{
    font-weight: 500;
}
.dropdown-toggle::after {
    border: 0;
}
.nav-link.dropdown-toggle{
    position: relative;
    bottom: 5px;
}
.ri-arrow-down-s-line{
    font-size: 20px;
    position: relative;
    top: 2.5px;
}
.nav-link.active{
    font-weight: 700;
}
button.navbar-toggler, button.navbar-toggler:focus-visible, button:focus:not(:focus-visible){
    border: none;
    box-shadow: none;
}
div#navbarSupportedContent{
    position: relative;
    top: 24px;
    z-index: 10;
    min-width: calc(100vw - 62px);
    left: -28px;
    border-radius: 1rem;
    padding: 6px 16px;
    background-color: var(--white);
}
img.logo{
    height: 32px;
}
/* RESPONSIVE */
@media (min-width:500px){
    div#navbarSupportedContent{
        min-width: calc(100vw - 69px);
    }
}

@media (min-width:768px) {
    div#navbarSupportedContent{
        min-width: calc(100vw - 90px);
    }
    div#navbarSupportedContent ul{
        display: flex;
        flex-direction: row;
    }
    .navbar-nav .nav-item{
        width: 50%
    }
}

@media (min-width:992px) {
    div#navbarSupportedContent{
        position: relative;
        top: 0px;
        min-width: auto;
        left:0px;
        box-shadow: none;
        padding: 0;
    }
    .sections .navbar-nav{
        width: 100%;
        display: flex;
        justify-content: flex-end;
    }
    .navbar-nav .nav-item{
        padding: 0 0.2rem;
        width: fit-content;
    }
    .navbar-nav .nav-item .action-button.violet {
        width: 160px;
        margin: 0.5rem 0;
    }
}
</style>
