<template>
    <section id="appointment">
        <div id="carouselNovocel" class="carousel slide img-container col-12 col-lg-11" data-bs-ride="carousel">
            <div class="carousel-inner">
                <div class="carousel-item active">
                    <PictureStructure 
                    :srcWebp="ReceptionWebp"
                    :srcWebp320="ReceptionWebp320"
                    :srcWebp480="ReceptionWebp480"
                    :srcWebp768="ReceptionWebp768"
                    :srcWebp1080="ReceptionWebp1080"
                    altImg="Recepción de Novocel"
                    classes="d-block w-100"
                    />
                </div>
                <div class="carousel-item">
                    <PictureStructure 
                    :srcWebp="Consulta1Webp"
                    :srcWebp320="Consulta1Webp320"
                    :srcWebp480="Consulta1Webp480"
                    :srcWebp768="Consulta1Webp768"
                    :srcWebp1080="Consulta1Webp1080"                    
                    altImg="Sala de consulta de Novocel"
                    classes="d-block w-100"
                    />
                </div>
                <div class="carousel-item">
                    <PictureStructure 
                    :srcWebp="Consulta2Webp"
                    :srcWebp320="Consulta2Webp320"
                    :srcWebp480="Consulta2Webp480"
                    :srcWebp768="Consulta2Webp768"
                    :srcWebp1080="Consulta2Webp1080"                    
                    altImg="Sala de consulta de Novocel"
                    classes="d-block w-100"
                    />
                </div>
                <div class="carousel-item">
                    <PictureStructure 
                    :srcWebp="EsperaWebp"
                    :srcWebp320="EsperaWebp320"
                    :srcWebp480="EsperaWebp480"
                    :srcWebp768="EsperaWebp768"
                    :srcWebp1080="EsperaWebp1080"                    
                    altImg="Sala de espera de Novocel"
                    classes="d-block w-100"
                    />
                </div>
                <div class="carousel-item">
                    <PictureStructure 
                    :srcWebp="SalaWebp"
                    :srcWebp320="SalaWebp320"
                    :srcWebp480="SalaWebp480"
                    :srcWebp768="SalaWebp768"
                    :srcWebp1080="SalaWebp1080"
                    altImg="Sala de consulta de Novocel"
                    classes="d-block w-100"
                    />
                </div>
            </div>
            <button class="carousel-control-prev" type="button" data-bs-target="#carouselNovocel" data-bs-slide="prev">
                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                <span class="visually-hidden">Previous</span>
            </button>
            <button class="carousel-control-next" type="button" data-bs-target="#carouselNovocel" data-bs-slide="next">
                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                <span class="visually-hidden">Next</span>
            </button>
        </div>
        <div class="information col-10 col-lg-6">
            <p class="title"><span class="secondText">Agende ahora</span> su consulta médica</p> 
            <p class="description">Déjanos tus datos y nuestro equipo se contactará para agendar o llámanos al <a class="phone-number" href=tel:+05890000111>+56930580356</a></p>
            <ButtonAction link="https://s3.philaxmed.cl/ReservaOnline.html?mc=Cl%C3%ADnicaNovocel#_" :targetBlank=true text="Agendar consulta" class="schedule" backColor="white"/>
        </div>
    </section>
</template>

<script>
import ButtonAction from '@/components/ButtonAction.vue';
import PictureStructure from '@/components/PictureStructure.vue';

import ReceptionWebp from '@/assets/img/clinica/webp/recepcion.webp';
import ReceptionWebp320 from '@/assets/img/clinica/webp/recepcion_320.webp';
import ReceptionWebp480 from '@/assets/img/clinica/webp/recepcion_480.webp';
import ReceptionWebp768 from '@/assets/img/clinica/webp/recepcion_768.webp';
import ReceptionWebp1080 from '@/assets/img/clinica/webp/recepcion_1080.webp';

import Consulta1Webp from '@/assets/img/clinica/webp/consulta1.webp';
import Consulta1Webp320 from '@/assets/img/clinica/webp/consulta1_320.webp';
import Consulta1Webp480 from '@/assets/img/clinica/webp/consulta1_480.webp';
import Consulta1Webp768 from '@/assets/img/clinica/webp/consulta1_768.webp';
import Consulta1Webp1080 from '@/assets/img/clinica/webp/consulta1_1080.webp';

import Consulta2Webp from '@/assets/img/clinica/webp/consulta2.webp';
import Consulta2Webp320 from '@/assets/img/clinica/webp/consulta2_320.webp';
import Consulta2Webp480 from '@/assets/img/clinica/webp/consulta2_480.webp';
import Consulta2Webp768 from '@/assets/img/clinica/webp/consulta2_768.webp';
import Consulta2Webp1080 from '@/assets/img/clinica/webp/consulta2_1080.webp';

import EsperaWebp from '@/assets/img/clinica/webp/espera.webp';
import EsperaWebp320 from '@/assets/img/clinica/webp/espera_320.webp';
import EsperaWebp480 from '@/assets/img/clinica/webp/espera_480.webp';
import EsperaWebp768 from '@/assets/img/clinica/webp/espera_768.webp';
import EsperaWebp1080 from '@/assets/img/clinica/webp/espera_1080.webp';

import SalaWebp from '@/assets/img/clinica/webp/saladep.webp';
import SalaWebp320 from '@/assets/img/clinica/webp/saladep_320.webp';
import SalaWebp480 from '@/assets/img/clinica/webp/saladep_480.webp';
import SalaWebp768 from '@/assets/img/clinica/webp/saladep_768.webp';
import SalaWebp1080 from '@/assets/img/clinica/webp/saladep_1080.webp';

export default {
    name: 'SectionAppointment',
    components: {
        ButtonAction, PictureStructure,
    },
    data() {
        return {
            ReceptionWebp, ReceptionWebp320, ReceptionWebp480, ReceptionWebp768, ReceptionWebp1080,
            Consulta1Webp, Consulta1Webp320, Consulta1Webp480, Consulta1Webp768, Consulta1Webp1080,
            Consulta2Webp, Consulta2Webp320, Consulta2Webp480, Consulta2Webp768, Consulta2Webp1080,
            EsperaWebp, EsperaWebp320, EsperaWebp480, EsperaWebp768, EsperaWebp1080,
            SalaWebp, SalaWebp320, SalaWebp480, SalaWebp768, SalaWebp1080,
        }
    },
}
</script>

<style scoped>
#appointment{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 5rem;
    max-width: 1400px;
    margin: auto;
    padding-top: 4rem;
}
.information{
    background-color: var(--dark-violet);
    border-radius: 1rem;
    padding: 1.5rem 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    position: relative;
    bottom: 140px;
    margin-bottom: -130px;
}
.title{
    color: var(--white);
    font-size: 40px;
    line-height: 45px;
    font-weight: 600;
    letter-spacing: 0px;
    margin-bottom: 20px;
}
.description{
    color: var(--white);
}
.secondText{
    color: var(--light-violet);
    color: var(--white);
}
.img-container{
    border-radius: 1rem;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 400px;
}
.img-container img{
    height: 400px;
    width: 100%;
    object-fit: cover;
}
.schedule {
    min-width: 200px;
}
.phone-number{
    color: var(--background);
    font-weight: 600;
    text-decoration: none;
}
/* RESPONSIVE */
@media (min-width:576px) {
    .title{
        font-size: 50px;
        line-height: 55px;
    }
    .information{
        padding: 1.5rem;
    }
}
@media (min-width:992px) {
    .information{
        bottom: 160px;
        margin-bottom: -150px;
    }
}
@media (min-width:1200px) {
    .information{
        padding: 3rem;
        bottom: 200px;
        margin-bottom: -180px;
    }
    .img-container{
        height: 550px;
    }
    .img-container img{ 
        height: 550px;
        width: 100%;
    }
    .title{
        font-size: 60px;
        line-height: 62px;
    }
}
</style>