<template>
    <section id="clinic" class="carousel-cards-container">
        <swiper 
        :breakpoints="{
            768: {
                slidesPerView: 2,
            },
            900: {
                slidesPerView: 3,
            },
            1024: {
                slidesPerView: 4,
            },
            1300: {
                slidesPerView: 5,
            },
        }" 
        :spaceBetween="20" 
        :modules="modules" 
        :autoplay="{
            delay: 0,
            disableOnInteraction: true,
        }"
        :loop="true" 
        :speed="4000" 
        class="mySwiper m-0">
            <swiper-slide>         
                <ImageCard 
                :srcWebp="ReceptionWebp"
                :srcWebp320="ReceptionWebp320"
                :srcWebp480="ReceptionWebp480"
                :srcWebp768="ReceptionWebp768"
                :srcWebp1080="ReceptionWebp1080"
                altImg="Recepción de Novocel"
                class="mt-7" 
                />
            </swiper-slide>
            <swiper-slide>
                <ImageCard 
                :srcWebp="EsperaWebp"
                :srcWebp320="EsperaWebp320"
                :srcWebp480="EsperaWebp480"
                :srcWebp768="EsperaWebp768"
                :srcWebp1080="EsperaWebp1080"
                altImg="Sala de espera de Novocel"
                class="mb-7" 
                />
            </swiper-slide>
            <swiper-slide>
                <ImageCard 
                :srcWebp="SalaWebp"
                :srcWebp320="SalaWebp320"
                :srcWebp480="SalaWebp480"
                :srcWebp768="SalaWebp768"
                :srcWebp1080="SalaWebp1080"
                altImg="Sala médica" class="mt-7" />
            </swiper-slide>
            <swiper-slide>
                <ImageCard 
                :srcWebp="Consulta1Webp"
                :srcWebp320="Consulta1Webp320"
                :srcWebp480="Consulta1Webp480"
                :srcWebp768="Consulta1Webp768"
                :srcWebp1080="Consulta1Webp1080"
                altImg="Consultorio de Novocel" class="mb-7" />
            </swiper-slide>
            <swiper-slide>
                <ImageCard 
                :srcWebp="Consulta2Webp"
                :srcWebp320="Consulta2Webp320"
                :srcWebp480="Consulta2Webp480"
                :srcWebp768="Consulta2Webp768"
                :srcWebp1080="Consulta2Webp1080"
                altImg="Consultorio de Novocel" class="mt-7" />
            </swiper-slide>
            <swiper-slide>         
                <ImageCard 
                :srcWebp="ReceptionWebp"
                :srcWebp320="ReceptionWebp320"
                :srcWebp480="ReceptionWebp480"
                :srcWebp768="ReceptionWebp768"
                :srcWebp1080="ReceptionWebp1080"
                altImg="Recepción de Novocel"
                class="mb-7" 
                />
            </swiper-slide>
            <swiper-slide>
                <ImageCard 
                :srcWebp="EsperaWebp"
                :srcWebp320="EsperaWebp320"
                :srcWebp480="EsperaWebp480"
                :srcWebp768="EsperaWebp768"
                :srcWebp1080="EsperaWebp1080"
                altImg="Sala de espera de Novocel"
                class="mt-7" 
                />
            </swiper-slide>
            <swiper-slide>
                <ImageCard 
                :srcWebp="SalaWebp"
                :srcWebp320="SalaWebp320"
                :srcWebp480="SalaWebp480"
                :srcWebp768="SalaWebp768"
                :srcWebp1080="SalaWebp1080"
                altImg="Sala médica" class="mb-7" />
            </swiper-slide>
            <swiper-slide>
                <ImageCard 
                :srcWebp="Consulta1Webp"
                :srcWebp320="Consulta1Webp320"
                :srcWebp480="Consulta1Webp480"
                :srcWebp768="Consulta1Webp768"
                :srcWebp1080="Consulta1Webp1080"
                altImg="Consultorio de Novocel" class="mt-7" />
            </swiper-slide>
            <swiper-slide>
                <ImageCard 
                :srcWebp="Consulta2Webp"
                :srcWebp320="Consulta2Webp320"
                :srcWebp480="Consulta2Webp480"
                :srcWebp768="Consulta2Webp768"
                :srcWebp1080="Consulta2Webp1080"
                altImg="Consultorio de Novocel" class="mb-7" />
            </swiper-slide>
        </swiper>
    </section>
</template>

<script>
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/pagination';
import { FreeMode, Autoplay } from 'swiper/modules';
import ImageCard from '@/components/ImageCard.vue';

import ReceptionWebp from '@/assets/img/clinica/webp/recepcion.webp';
import ReceptionWebp320 from '@/assets/img/clinica/webp/recepcion_320.webp';
import ReceptionWebp480 from '@/assets/img/clinica/webp/recepcion_480.webp';
import ReceptionWebp768 from '@/assets/img/clinica/webp/recepcion_768.webp';
import ReceptionWebp1080 from '@/assets/img/clinica/webp/recepcion_1080.webp';

import Consulta1Webp from '@/assets/img/clinica/webp/consulta1.webp';
import Consulta1Webp320 from '@/assets/img/clinica/webp/consulta1_320.webp';
import Consulta1Webp480 from '@/assets/img/clinica/webp/consulta1_480.webp';
import Consulta1Webp768 from '@/assets/img/clinica/webp/consulta1_768.webp';
import Consulta1Webp1080 from '@/assets/img/clinica/webp/consulta1_1080.webp';

import Consulta2Webp from '@/assets/img/clinica/webp/consulta2.webp';
import Consulta2Webp320 from '@/assets/img/clinica/webp/consulta2_320.webp';
import Consulta2Webp480 from '@/assets/img/clinica/webp/consulta2_480.webp';
import Consulta2Webp768 from '@/assets/img/clinica/webp/consulta2_768.webp';
import Consulta2Webp1080 from '@/assets/img/clinica/webp/consulta2_1080.webp';

import EsperaWebp from '@/assets/img/clinica/webp/espera.webp';
import EsperaWebp320 from '@/assets/img/clinica/webp/espera_320.webp';
import EsperaWebp480 from '@/assets/img/clinica/webp/espera_480.webp';
import EsperaWebp768 from '@/assets/img/clinica/webp/espera_768.webp';
import EsperaWebp1080 from '@/assets/img/clinica/webp/espera_1080.webp';

import SalaWebp from '@/assets/img/clinica/webp/saladep.webp';
import SalaWebp320 from '@/assets/img/clinica/webp/saladep_320.webp';
import SalaWebp480 from '@/assets/img/clinica/webp/saladep_480.webp';
import SalaWebp768 from '@/assets/img/clinica/webp/saladep_768.webp';
import SalaWebp1080 from '@/assets/img/clinica/webp/saladep_1080.webp';

export default {
    name: "SectionClinic",
    components: {
        Swiper,
        SwiperSlide,
        ImageCard,
    },
    setup() {
        return {
            modules: [FreeMode, Autoplay],
        };
    },
    data() {
        return {
            ReceptionWebp, ReceptionWebp320, ReceptionWebp480, ReceptionWebp768, ReceptionWebp1080,
            Consulta1Webp, Consulta1Webp320, Consulta1Webp480, Consulta1Webp768, Consulta1Webp1080,
            Consulta2Webp, Consulta2Webp320, Consulta2Webp480, Consulta2Webp768, Consulta2Webp1080,
            EsperaWebp, EsperaWebp320, EsperaWebp480, EsperaWebp768, EsperaWebp1080,
            SalaWebp, SalaWebp320, SalaWebp480, SalaWebp768, SalaWebp1080,
        }
    },
};
</script>


<style scoped>
.mySwiper {
    cursor: grab;
}
.carousel-cards-container {
    margin-top: 3rem;
}
.p-0 {
    padding: 0 !important;
}
@media (min-width:768px) {
    .carousel-cards-container {        
        min-width: calc(100% + 90px); 
        position: relative;
        left: -45px; 
    }
}
@media (min-width:992px) {
    .carousel-cards-container {
        min-width: calc(100% + 155px);
        left: -80px;
        overflow: hidden;
    }
    .mySwiper {      
        min-width: calc(100% + 310px);
        left: -155px;
    }
}
@media (min-width:1024px) {
    .carousel-cards-container {       
        min-width: calc(100% + 150px);
        left: -75px;
    }
    .mySwiper {
        min-width: calc(100% + 300px);
        left: -150px;
    }
}
@media (min-width: 1200px) {
    .mySwiper {
        min-width: calc(100% + 300px);
        left: -150px;
    }
    .carousel-cards-container {       
        min-width: calc(100% + 150px);
        left: -75px;
        margin-top: 0;
    }
}
</style>
<style>
.swiper-wrapper {
    align-items: center;
}
</style>