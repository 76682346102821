<template>
    <picture>
        <source srcset="../assets/img/decoration/double-curve.webp" type="image/webp">
        <source srcset="../assets/img/decoration/double-curve.png" type="image/png">
        <source srcset="../assets/img/decoration/double-curve.svg" type="image/svg+xml" />
        <img src="../assets/img/decoration/double-curve.webp" alt="Curva decorativa con degradado" />
    </picture>
</template>

<script>
export default {
    name: 'DecorationCurveDouble',
    components: {
    },

}
</script>

<style scoped>
img{
    max-width: 100%;
    z-index: -2;
}
/* RESPONSIVE */

@media (min-width:768px) {
    img{
        max-width: 80%;
    }
}
</style>