<template>
    <img
        :srcset="`${srcWebp320} 480w, ${srcWebp480} 768w, ${srcWebp768} 1080w, ${srcWebp1080} 1200w, ${srcWebp} 1400w`"
        sizes="(max-width: 400px) 100%, (max-width: 480px) 100%, (max-width: 768px) 100%, (max-width: 1080px) 100%, 1200px"
        :src=srcWebp :alt=altImg loading="lazy" :class=classes 
    />
</template>

<script>
export default {
    name: 'PictureStructure',
    props:  {
        altImg: { type: String, },
        classes: { type: String, },
        srcWebp: { type: String, },
        srcWebp320: { type: String, },
        srcWebp480: { type: String, },
        srcWebp768: { type: String, },
        srcWebp1080: { type: String, },
    },
}
</script>

<style scoped>
img{
    width: auto;
    height: auto;
}
</style>