<template>
    <picture>
        <source srcset="../assets/img/decoration/curve-left.webp" type="image/webp">
        <source srcset="../assets/img/decoration/curve-left.png" type="image/png">
        <source srcset="../assets/img/decoration/curve-left.svg" type="image/svg+xml" />
        <img src="../assets/img/decoration/curve-left.webp" alt="Curva decorativa con degradado" />
    </picture>
</template>

<script>
export default {
    name: 'DecorationCurveLeft',
    components: {
    },

}
</script>

<style scoped>
/* RESPONSIVE */

@media (min-width:768px) {}

@media (min-width:992px) {}

@media (min-width:1200px) {}

@media (min-width:1400px) {}
</style>