<template>
    <div class="section-name" :class="{ 'm-auto': alignCenter }">
        <p>
            {{text}}
        </p>
    </div>
</template>

<script>
export default {
    name: 'SectionName',
    props: {
        text: {
            type: String,
        },
        alignCenter: {
            type: Boolean,
            default: false,
        },
    },
}
</script>

<style scoped>
.section-name p {
    border: 1px solid var(--dark-violet);
    border-radius: 42px;
    width: fit-content;
    text-transform: uppercase;
    margin: 0;
    padding: 0.4rem 1.4rem;
    font-size: 0.9rem;
    font-weight: 600;
    text-align: center;
}
</style>