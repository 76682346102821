<template>
    <router-link v-if="to" :to="to" class="no-decoration">
        <div class="card">
            <SectionName :text="pathologie" class="section"/>
            <div>
                <p class="description-title">{{ descriptionTitle }}</p>
                <p class="description-text"> {{ descriptionText }}</p>
            </div>
            <div class="decoration-container">
                <DecorationCurveDouble />
            </div>       
        </div>
    </router-link>
    <div v-else class="card" :class="{'landing' : landing}">
        <SectionName :text="pathologie" class="section"/>
        <div>
            <p class="description-title">{{ descriptionTitle }}</p>
            <p class="description-text"> {{ descriptionText }}</p>
        </div>
        <div v-if="!landing" class="decoration-container">
            <DecorationCurveDouble />
        </div>       
    </div>
</template>

<script>
import SectionName from './SectionName.vue';
import DecorationCurveDouble from './DecorationCurveDouble.vue';

export default {
    name: 'CardPathologiesIntro',
    components: {
        SectionName,
        DecorationCurveDouble,
    },
    props:  {
        pathologie: {
            type: String,
        },
        descriptionTitle: {
            type: String,
        },
        descriptionText: {
            type: String,
        },
        to: {
            type: String,
        },
        landing: {
            type: Boolean,
        }
    },
}
</script>

<style scoped>
.no-decoration{
    text-decoration: none;
}
.card{
    border-radius: 1rem;
    background-color: var(--white);
    border: none;
    padding: 1.5rem;
    display: flex;
    justify-content: space-between;
    /* min-height: 450px; */
    overflow: hidden;
}
.description-title{
    font-weight: 600;
    font-size: 35px;
    line-height: 40px;
    padding-top: 2.5rem;
}
.description-text{
    font-size: 20px;
    line-height: 25px;
    margin-bottom: 0;
}
.section, .description-title, .description-text{
    position: relative;
    z-index: 2;
}
.section{
    padding-bottom: 2rem;
}
.decoration-container{
    -webkit-transform: rotate(150deg) scaleX(-1); 
    transform: rotate(150deg) scaleX(-1);
    overflow: hidden;
    width: max-content;
    position: absolute;
    z-index: 1;
    top: 180px;
    left: -40px;
}
.landing{
    background: var(--card-gradient);
}
/* RESPONSIVE */
@media (max-width: 992px) {
    .card{
        height: auto;
    }
}
@media (min-width: 992px) {
    .card{
        min-height: 450px;
    }
}
@media (min-width:500px) {
    .description-title{
        font-size: 40px;
        line-height: 43px;
    }
    .description-text{
        font-size: 20px;
        line-height: 27px;
    }
}
</style>